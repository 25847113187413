<template>
	<div class="row">
		<div class="col-12">
			<div class="box">
				<LoadingSpinner v-if="loading" />
				<div v-else-if="stocks.proprietaires" class="row">
					<div class="col-12">
						<h3>{{ $t('monte.congelation.proprietaire_stallion') }}</h3>
						<table class="table table-hover table-sm mb-0">
							<thead class="sticky">
								<tr>
									<th class="w-50">{{ $t('monte.congelation.norme') }}</th>
									<th class="w-25">{{ $t('monte.congelation.stock') }}</th>
									<th class="w-25">{{ $t('monte.congelation.stock_theorique') }}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="w-50">{{ $t('global.total') }}</td>
									<td class="w-25">{{ stocks.proprietaires.total.nb_stock }}</td>
									<td class="w-25">{{ stocks.proprietaires.total.nb_theorique }}</td>
								</tr>
								<template v-for="norme in stocks.proprietaires.normes">
									<tr v-if="norme.nb_stock > 0 || norme.nb_theorique > 0" :key="norme.protocole_id">
										<td class="w-50">{{ norme.protocole_label }}</td>
										<td class="w-25">{{ norme.nb_stock }}</td>
										<td class="w-25">{{ norme.nb_theorique }}</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>

					<div v-for="tiers in stocks.tiers" class="col-12 mt-2" :key="tiers.tiers_id">
						<template v-if="tiers.stocks.total.nb_stock > 0 || tiers.stocks.total.nb_theorique > 0">
							<h3>{{ tiers.tiers_rs }}</h3>
							<table class="table table-hover table-sm mb-0">
								<thead class="sticky">
									<tr>
										<th class="w-50">{{ $t('monte.congelation.norme') }}</th>
										<th class="w-25">{{ $t('monte.congelation.stock') }}</th>
										<th class="w-25">{{ $t('monte.congelation.stock_theorique') }}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="w-50">{{ $t('global.total') }}</td>
										<td class="w-25">{{ tiers.stocks.total.nb_stock }}</td>
										<td class="w-25">{{ tiers.stocks.total.nb_theorique }}</td>
									</tr>
									<template v-for="norme in tiers.stocks.normes">
										<tr v-if="norme.nb_stock > 0 || norme.nb_theorique > 0" :key="norme.protocole_id">
											<td class="w-50">{{ norme.protocole_label }}</td>
											<td class="w-25">{{ norme.nb_stock }}</td>
											<td class="w-25">{{ norme.nb_theorique }}</td>
										</tr>
									</template>
								</tbody>
							</table>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"

	export default {
		name: 'StallionStocksNormes',
		mixins: [Congelation],
		props: ['horse_id'],
		data () {
			return {
				loading: false,
				stocks: {}
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading = true
				this.stocks = await this.getHorseStockNormes(this.horse_id)
				this.loading = false
			},
		},

		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>